<template>
  <div class="modal modal_wrap">
    <div class="modal_popup">
      <span class="modal__close" @click="close">
         <svg-icon name="times"/>
        </span>
      <p class="text text_white" v-if="from">The draft #{{id}} will be deleted. Please press Continue to proceed or Cancel to keep it.</p>
      <p class="text text_white" v-else>Are you sure you want to delete this order?</p>
      <div class="justify-content-between">
        <button class="btn btn_transparent" @click="close"><svg-icon name="solid/step-backward" /> Back</button>
        <button class="btn btn_transparent" @click="deleteOrder"><svg-icon name="solid/trash" /> Continue</button>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex';

  export default {
    name: "DeleteOrder",

    props: {
      from: String,
      deleteAction: Function,
    },

    methods: {
      ...mapActions('filterOrders', ['getListStatuses']),
      close(){
        this.$store.commit('orderPreview/delete', false);
      },

      deleteOrder(){
        if(this.deleteAction && typeof this.deleteAction === 'function') return this.deleteAction()
        this.$store.dispatch('orderPreview/deleteOrder', this.id);
        this.$store.commit('orderPreview/delete', false);

        if(this.from){
          setTimeout(() => {
            this.$store.dispatch('orderItems/getOrders');
            this.getListStatuses()
          }, 300);
        }
      }
    },

    computed: {
      ...mapGetters({
        open: 'orderPreview/delete',
        id: 'orderPreview/id',
      }),
    }

  }
</script>

<style scoped lang="scss">
  .justify-content-between {
    display: flex;
  }

  .text_white{
    margin: 1.5rem 0 0.5rem;
  }
</style>
